import { PropsWithChildren } from "react"
import Footer from "./Footer"
import Navbar from "./Navbar"

interface LayoutProps {
  isNavbarLight?: boolean
}

const Layout: React.FC<PropsWithChildren<LayoutProps>> = ({ children, isNavbarLight }) => {
  return (
    <div className="light scroll-smooth">
      <div className="font-nunito text-base text-black dark:text-white dark:bg-slate-900">
        <Navbar isNavbarLight={isNavbarLight} />
        {children}
        <Footer />
      </div>
    </div>
  )
}

export default Layout