import Link from "next/link"
import { useEffect, useState } from "react"

export const useWindowScrollPositions = () => {
  const [scrollPosition, setPosition] = useState({ scrollX: 0, scrollY: 0 })

  useEffect(() => {
    function updatePosition() {
      setPosition({ scrollX: window.scrollX, scrollY: window.scrollY })
    }

    window.addEventListener('scroll', updatePosition)
    updatePosition()

    return () => window.removeEventListener('scroll', updatePosition)
  }, [])

  return scrollPosition
}

interface NavbarProps {
  isNavbarLight?: boolean
}

const Navbar: React.FC<NavbarProps> = ({ isNavbarLight }) => {
  const { scrollY } = useWindowScrollPositions()

  return (
    <nav id="topnav" className={`defaultscroll is-sticky ${scrollY === 0 ? '' : 'nav-sticky'}`}>
      <div className="container">

        {/* <a className="logo ltr:pl-0 rtl:pr-0" href="index.html">
        <img src="assets/images/logo-dark.png" className="inline-block dark:hidden" alt="" />
        <img src="assets/images/logo-light.png" className="hidden dark:inline-block" alt="" />
      </a> */}


        <div className="menu-extras">
          <div className="menu-item">

            <a className="navbar-toggle" id="isToggle">
              <div className="lines">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </a>

          </div>
        </div>


        <ul className="buy-button list-none mb-0">
          <li className="inline mb-0">
            <a href=""
              className="btn btn-icon rounded-full bg-indigo-600/5 hover:bg-indigo-600 border-indigo-600/10 hover:border-indigo-600 text-indigo-600 hover:text-white"><i
                data-feather="settings" className="h-4 w-4"></i></a>
          </li>

          <li className="inline ltr:pl-1 rtl:pr-1 mb-0">
            <a href="https://1.envato.market/techwind" target="_blank"
              className="btn btn-icon rounded-full bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white"><i
                data-feather="shopping-cart" className="h-4 w-4"></i></a>
          </li>
        </ul>


        <div id="navigation">

          <ul className={`navigation-menu ${isNavbarLight ? 'nav-light' : ''}`}>
            <li><Link href="/" className="sub-menu-item">Home</Link></li>
            <li><Link href="/workshops" className="sub-menu-item">Workshops</Link></li>
            <li><Link href="/contact" className="sub-menu-item">Contact</Link></li>
            <li><Link href="/" className="sub-menu-item">Faq</Link></li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Navbar